import React from 'react'
import { Link } from 'gatsby'

import './ProductInfoCard.scss'

class ProductInfoCard extends React.Component {
  render () {
    return (
      <div className="product-info-card">
        <div className="card-text">
          <h4 className="card-title">{this.props.siteMeta.name}</h4>
        </div>
        <p className="card-description">{this.props.siteMeta.about}</p>
        <Link className="card-button" to="/">
          Try it now →
        </Link>
      </div>
    )
  }
}

export default ProductInfoCard
